import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/bjorn/git/entur-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "plugins"
    }}>{`Plugins`}</h1>
    <p>{`NOD Plugins implements business rules for modifying the Ticket Media's "Image". If the Plugin changes this Image, NOD will calculate the difference between old and new Image and deliver this as a series of client commands to the NOD client.
Each Order in an Order Group is executed by an individual Plugin that alters together Image with the desired content. Several Plugins can thus be combined to realize an Order Group and the change is delivered together from NOD to the NOD Client.
A Plugin owns the definition of what an Order should contain; The NOD platform has no dependencies on the contents of an Order. This means that new Orders can be distributed via NOD by registering a new Plugin in NOD that can read this Order.
A PTO can even develop a Plugin that will not necessarily be used by other PTOs. The NOD platform therefore supports the distribution of private orders with execution on associated private plugins.`}</p>
    <h3 {...{
      "id": "at-the-nod-platform-the-following-plugins-are-currently-running"
    }}>{`At the NOD Platform, the following plugins are currently running:`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Plugin V821_18 (NSD card)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`PLugin V821-18 DFContents (NSD card content)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Plugin V821_18UL (Ultralight card)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`plugin V821-18ULContents (Ultralight card content)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Plugin V821_25 (Mobile Single & Periodic Ticket)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Plugin V821-25 RContents (Mobile Single & Periodic Ticket content)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Plugin INSPECTION (for checking tickets)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Plugin Account (Account tickets)`}</p>
      </li>
    </ul>
    <p>{`When executing an Order for a Travel Card, NOD will first read the entire contents of the card before it is submitted to the Plugin. In the case of mobile tickets, it is not necessary to read previous content on the ticket media, as a mobile ticket is always posted on a new Ticket medium (a new image).`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      